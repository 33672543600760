*{
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

:root{
    --primary: #365899;
    --darkprimary: #004885;
    --cards-color: #18689c;
    --nav-hover:rgb(126, 0, 0);
    --eventactive:#a91e10;
    --eventinactive: #d6d6d6;
    --lightbackg: #eeeeee;
    --pgrey:#565555;
    --bordernavitem:#4a69a3;
}

header{
    background-color: var(--primary);
    color: white;
}

.main-container{
    /* border: 2px solid; */
    max-width: 1170px;
    margin: auto;
}

header a{
    text-decoration: none;
    color: white;
}


/* -------------------uper left---------------- */

.uper-nav{
    display: flex;
    justify-content: space-between;
}

.uper-left{
    display: flex;
    align-items: center;
    width: 40%;;
}

.uper-left .logo{
    padding: 8px;
}

.uper-left .logo img{
    width: 70px;
}

/* -------------------uper right---------------- */

.uper-right{
    width: 60%;
    display: flex;
    justify-content: end;
    padding-top: 10px;
}

.uper-right ul{
display: flex;
list-style: none;
align-items:first baseline;
}

.uper-right ul .li{
display: flex;
}

.uper-right ul li{
    padding: 2px 15px 2px 15px;
     /* border-right: 1px solid; */
     font-size: 12px;
     font-weight: 600;
}

.uper-right ul a{
    /* padding: 2px 15px 2px 15px; */
     border-right: 1px solid;
     height: max-content;
     text-align: center;
     /* font-size: 14px; */
     color: #dedcdc;
}

.uper-right ul a:nth-child(5){
  border-right: none;
}

.g-search{
    padding: 7px;
    outline: none;
    border: none;
    width: 120px;
}

.g-btn{
    padding: 7px;
    border: none;
    outline: none;
    font-weight: 600;
    background-color: var(--darkprimary);
    color: white;
}



/* -----------------------lower-nav------------------- */

.lower-nav{
    /* margin-top: 10px; */
    border: 1px solid white;
    border-bottom:none
}

.lower-nav ul{
    display: flex;
    list-style: none; flex-wrap: wrap;
    justify-content: center;
}

.lower-nav ul li{
    display: inline-block;;
    padding: 13px 0px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
   
}

.lower-nav ul a {
    text-align: center;
    /* width: 100%; */
    width: 106px;
    /* border-left: 1px solid rgb(187, 182, 182); */
    border-left: 1px solid var(--bordernavitem);
}

.lower-nav ul a:hover {
    background-color: rgb(126, 0, 0);
}

.lower-nav ul a:nth-child(1) {
    border-left:none;
}


.multi-items{
    position: relative;
    /* border: 2px solid red; */
}

.multi-items:hover .more-items{
    display: flex;
    flex-direction: column;
    
}

.more-items{
    display: none;
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 99;
    width: 180px;
    background-color:white;
    /* border: 2px solid red; */

    /* display: flex;
    flex-direction: column; */
    box-shadow: 0px 0px 2px black;
   
}
.more-items a{
    border-bottom: 1px solid black;
    color: var(--primary);
    padding: 0px 10px 0px 18px;
    text-align: left!important;
    border-left: none!important;
    width: 100%!important;
}
.more-items a li{
    padding: 8px 0px;
}

.more-items a:hover{
    background-color: rgb(56, 90,149,1)!important;
    color: white;
}

.more-items a:last-child{
    border: none;
}


/* ----------------------res-nav----------------------- */

.res-div-btn{
    border: 1px solid;
    display: none;
}

.res-div-btn .res-btn{
    display: block;
    width: 100%;
    margin: 0px;
    padding: 8px;
    font-size: 13px;
    font-weight: bold;
    border: none;
    outline: none;
    background-color: var(--primary);
    color: white;
    cursor: pointer;
    text-align: center;
}

.res-div-btn input{
    display: none;
}


/* ---------navitem-------------- */

.res-lower-nav{
    /* margin-top: 10px; */
    /* border: 1px solid white; */
    border-bottom:none;
    display: none;
}

.res-lower-nav ul{
    display: flex;
    flex-direction: column;
    list-style: none; 
    align-items: center;
}

.res-lower-nav ul li{
    display: inline-block;;
    padding: 13px 0px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
   
}

.res-lower-nav ul a {
    text-align: center;
    width: 100%;
}

/* .res-multi-items:hover .res-more-items{
    display: flex;
    flex-direction: column;
} */

.res-more-items{
    display: none;
    z-index: 99;
    width: 100%;
    background-color:white;
    box-shadow: 0px 0px 2px black;
   
}
.res-more-items a{
    border-bottom: 1px solid black;
    color: var(--primary);
    padding: 0px 10px 0px 18px;
    text-align: left!important;
    border-left: none!important;
}
.res-more-items a li{
    padding: 8px 0px;
}

.res-more-items a:hover{
    background-color: rgb(56, 90,149,1)!important;
    color: white;
}

.res-more-items a:last-child{
    border: none;
}




/* -------------responsive----------------------- */

@media screen and (max-width:1000px) {
    .uper-nav{
        flex-direction: column;
        margin-bottom: 15px;
        /* align-items: center; */
    }

    .uper-left {
        width: 100%;
    }

    .uper-right {
        width: 100%;
        justify-content: center;
    }    
}


@media screen and (max-width:650px) {
    .res-div-btn{
        display: block;
    }

    .lower-nav{
        display: none;
    }
}

@media screen and (max-width:610px) {
    .uper-right ul{
        display: flex;
        flex-direction: column;
    }

    .uper-right ul .li{
        margin-bottom: 12px;
    }

    .uper-right ul .form{
        display: flex;
        justify-content: center;
        /* border: 2px solid red; */
        width: 100%;
    }

    .g-search{
        width: 180px;
    }
}

@media screen and (max-width:430px) {
    .uper-right ul .li li{
        padding: 2px 10px 2px 10px;
    }
    
}
*{
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

:root{
 --primary: #365899;
 --cards-color: #18689c;
 --nav-hover:rgb(126, 0, 0);
 --eventactive:#a91e10;
 --eventinactive: #d6d6d6;
 --lightbackg: #eeeeee;
 --pgrey:#565555;
 --bordernavitem:#4a69a3;
}

.footer{
    background-color: var(--primary);
    color: white;
    padding: 20px 0px 20px 0px;
}


.footer-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* border: 2px solid; */
    width: 95%;
    column-gap: 30px;
    margin-bottom: 30px;
}

.footer-section-content{
    /* border: 2px solid red; */
    width: 250px;
}

.footer-heading{
    border-top: 2px dotted #e0c664;
    border-bottom: 2px dotted #e0c664;
    margin-bottom: 9px;
}

.footer-section-content ul a{
    text-decoration: none;
    color: white;
    list-style: none;
}

.footer-section-content ul a li{
    margin-bottom:7px;
    font-size: 14px;
}

.footer-section-content .social-icon{
    display: flex;
    align-items: center;
    margin: 26px 0px;
}

.footer-section-content .social-icon .icons{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
}
.footer-section-content .social-icon .icons img{
      width: 30px;
}

.location {
    /* text-align: center; */
display: flex;
justify-content: center;
align-items: center;
}

.location img{
    width: 13px;
    margin-right: 5px;
    filter: invert(1);
}

.location a{
    color: white;
    text-decoration: none;
    font-size: 13px;
}


.lower-line-copyright{
    border-top: 1px solid rgb(140, 140, 140);
    display: flex;
    justify-content: center;
}

.copy-data{
    display: flex;
    justify-content: space-between;    
    /* border: 2px solid red; */
    padding-top: 15px;
    width: 85%;
}

.copy-data .cont-copy{
    font-size: 12px;
}

                